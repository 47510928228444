.gpt3__article{
    height: 100%;
    
}
.gpt3__article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-blog);
    min-height: 200px;
    padding: 1rem;
    height: 100%;
}
.gpt3__article-top p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 11.649px;
  line-height: 35px;

  color: #ffffff;
}
.gpt3__article-top h4 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 19.3154px;
  line-height: 30px;
  color: #ffffff;
}
.gpt3__article-bottom p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 11.649px;
  line-height: 35px;
  color: #ffffff;
  cursor: pointer;
}

    /* .gpt3__article-image{
        width: 100%;
        height: 100%;
    }
    .gpt3__article-image img{
        width: 100%;
        height: 100%;
    } */