.gpt3__cta {
  background: var(--gradient-text);
  border-radius: 10.7236px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.gpt3__cta-content p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;

  color: #0e0e0e;
}

.gpt3__cta-content h4 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 45px;

  color: #000000;
}
.gpt3__cta-btn {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;

  color: #ffffff;
}

.gpt3__cta-btn button {
  background: #000000;
  border-radius: 40px;
  padding: 12px 36px;
  min-width: 180px;
}
@media screen and (max-width: 650px) {
  .gpt3__cta {
    flex-direction: column;
  }
  .gpt3__cta-btn {
    margin-top: 1.5rem;
  }
  .gpt3__cta-content h4 {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .gpt3__cta-btn {
    margin-top: 1.5rem;
  }
  .gpt3__cta-content h4 {
    margin-top: 1rem;
    font-size: 22px;
  }
  .gpt3__cta-btn{
    font-size: 14px;
  }
}
