.gpt3__footer {
  background: var(--color-footer);
  display: flex;
  flex-direction: column;
}
.gpt3__footer-heading {
  max-width: 70%;
  margin: auto;
}
.gpt3__footer-heading h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;

  text-align: center;
  letter-spacing: -0.04em;
}
.gpt3__footer-btn {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.gpt3__footer-btn button {
  border: 1px solid #ffffff;
  padding: 22px 20px 21px 24px;
  font-family: "Gilroy-Medium";
  font-size: 18px;
  line-height: 21px;

  color: #ffffff;
}
.gpt3__footer-content {
  display: flex;
  justify-content: space-around;
  margin-top: 185px;
  flex-wrap: wrap;
}
.gpt3__footer-content-logo {
  max-width: 170px;
}
.gpt3__footer-content-logo img {
  width: 118px;
  margin-bottom: 25px;
}

.gpt3__footer-content p {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  color: #ffffff;
}
.gpt3__footer-content h3 {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 36px;
  color: #ffffff;
}
.gpt3__footer-content-links,
.gpt3__footer-content-company,
.gpt3__footer-content-getintouch {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.gpt3__footer-content-getintouch p {
  cursor: default;
}
.gpt3__footer-copyright p {
  text-align: center;
  margin-top: 80px;
  font-family: "Gilroy-Medium";
  font-size: 12px;
  line-height: 14px;
  font-feature-settings: "liga" off;

  color: #ffffff;
}
.gpt3__footer-content div{
    min-width: 250px;
    margin: 1rem;
}
@media screen and (max-width:1050px) {
    .gpt3__footer-heading h2{
        font-size: 34px;
        line-height: 45px;   

    }
    .gpt3__footer-content div{
        margin-bottom: 3rem;
    }
}