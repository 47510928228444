.gpt3__navbar-links img {
  width: 62.56px;
  height: 16.02px;
}
.gpt3__navbar-links_container li {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  margin: 0 1rem;
}
.gpt3__navbar-sign,.gpt3__navbar-menu_container-links-sign {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
}
.gpt3__navbar-sign button ,
.gpt3__navbar-menu_container-links-sign button{
  background: #ff4820;
  border-radius: 5px;
  padding: 16px 45px;
  margin-left: 26px;
}
.gpt3__navbar-menu {
  display: none;
  position: relative;
  margin-left: 1rem;
}
.gpt3__navbar-menu svg {
  cursor: pointer;
}
.gpt3__navbar-menu_container,
.gpt3__navbar-menu_container-links,
.gpt3__navbar-menu_container-links-sign,
.gpt3__navbar-menu_container-links ul {
  display: flex;
  flex-direction: column;
}
.gpt3__navbar-menu_container-links ul li{
    margin: 1rem 0;
}
.gpt3__navbar-menu_container{
    justify-content: flex-end;
    align-items: flex-end;
    text-align: end;
    background: var(--color-footer);

    padding: 2rem;
    margin-top: 1rem;
    min-width: 210px;

    border-radius: 5px;
    box-shadow: 10 10 0 rgb(33, 249, 249);

    position: absolute;
    top: 40px;
    right: 0;
}
@media screen and (max-width:1050px){
    .gpt3__navbar-links_container{
        display: none;
    }
    .gpt3__navbar-menu{
        display: flex;
    }
    .gpt3__navbar-menu_container-links-sign{
        display: none;
    }
}
@media screen and (max-width:700px){
    .gpt3__navbar{
        padding: 2rem 4rem;
    }
}
@media screen and (max-width:550px){
    .gpt3__navbar{
        padding: 2rem;
    }
    .gpt3__navbar-sign{
        display: none;
    }
    .gpt3__navbar-menu_container{
        top: 20px;
    }
    .gpt3__navbar-menu_container-links-sign{
        display: block;
        margin-top: 1rem;
    }
    .gpt3__navbar-menu_container-links-sign button{
        padding: 0.5rem 1rem;
        margin: 1rem 0;
    }
}


.gpt3__navbar-links_container li{
  cursor: pointer;
}
