.gpt3__header {
  display: flex;
}
.gpt3__header-content {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2rem;
}
.gpt3__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;

  letter-spacing: -0.04em;
}
.gpt3__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  color: var(--color-text);
}
.gpt3__header-content_input {
  display: flex;
  width: 100%;
}
.gpt3__header-content_input input {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  padding: 0 1rem;
  outline: none;
  border: 2px solid var(--color-footer);
  width: 100%;
  min-height: 50px;
  flex: 2;
  color: #3d6184;

  background: var(--color-footer);
  border-radius: 5px 0px 0px 5px;
}
.gpt3__header-content_input button {
  width: 100%;
  flex: 0.6;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;

  background: #ff4820;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
}
.gpt3__header-content_people {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gpt3__header-content_people img {
  width: 181.79px;
  height: 38px;
}
.gpt3__header-content_people p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #ffffff;
  margin-left: 1rem;
}
.gpt3__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gpt3__header-image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width:1050px){
    .gpt3__header{
        flex-direction: column;
        gap: 3rem;
    }
}
@media screen and (max-width:650px){
    .gpt3__header-content h1{
        font-size: 48px;
        line-height: 60px;
    }
    .gpt3__header-content p{
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-content_people{
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
    .gpt3__header-content_people p{
        margin: 0;
    }
    .gpt3__header-content_input input,
    .gpt3__header-content_input button{
        font-size: 16px;
        line-height: 24px;
    }
}
@media screen and (max-width:450px){
    .gpt3__header-content h1{
        font-size: 36px;
        line-height: 48px;
    }
    .gpt3__header-content p{
        font-size: 14px;
        line-height: 24px;
    }
    .gpt3__header-content_input input,
    .gpt3__header-content_input button{
        font-size: 12px;
        line-height: 16px;
    }
}
